import TimeTableStuWeekPage from "@/pages/mobile/edu/time_table_week";
import AttendCalendarPage from "@/pages/mobile/edu/attend/calendar";

const stuRouterArr:object[] = [
  {
    path: "/mobile/edu/time_table_week",
    element: <TimeTableStuWeekPage></TimeTableStuWeekPage>,
  },
  {
    path: "/mobile/edu/attend_calendar",
    element: <AttendCalendarPage></AttendCalendarPage>,
  },
];

export default stuRouterArr;