import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import LoadingComponent from "@/pcomponents/common/content/loading";
import TodoInfoArrFunc from "@/pcomponents/common/content/todo/list/info_arr_func";
import DailyDataFunc from "@/pages/mobile/manage/edu/daily_report/common/card_list/func/daily_data_func";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import AddAttendPopup from "@/pages/mobile/manage/plan/popup/add_attend";
import ViewTodoStuPopup from "@/pages/mobile/manage/plan/popup/view_todo_stu";
import ViewStuInfoPopup from "@/pages/mobile/manage/plan/popup/view_stu_info";
import TodoWritePopup from "@/pages/mobile/plan/todo/popup/todo_write";
import FindStudyTitlePopup from "@/pages/mobile/manage/plan_study/study/popup/find_study_title";
import SearchArea from "./area/search";
import DailyListArea from "./area/daily_list";

function DailyReportCardListArea(props: any) {
  let myProps = {
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 200,
    'order_id': 'a_stu_grade, a_user_name',
    'order_type': '',
    "is_add_idx_info": "1",
    s_grade: ["student"],
    "s_class_seq": "",
    "s_school_name": "",
    "s_stu_grade": "",
    "s_stu_state": "now",
    "s_is_par_join": "",
    "s_search_text_type": "name",
    "s_search_text": "",
    "s_user_name_like": "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_lecture_time: "1",
    s_addon_link_user: "1",
    s_addon_daily_report_last: "1",
    s_addon_study_det_by_daily_last: "1",
    s_addon_daily_report: "1",
    s_addon_daily_report_start_date: now_date_str,
    s_addon_daily_report_end_date: now_date_str,
    s_addon_todo: "1",
    s_addon_todo_start_date: now_date_str,
    s_addon_todo_end_date: now_date_str,
    s_addon_todo_s_todo_sort: "",
    s_addon_todo_elapse_time: "1",
    s_addon_todo_s_state: "",
    s_addon_todo_addon_par: "",
    s_addon_home_work_file: "1",
    s_addon_home_work_file_start_date: now_date_str,
    s_addon_home_work_file_end_date: now_date_str,
    s_addon_plan_study_file: "1",
    s_addon_plan_study_file_start_date: now_date_str,
    s_addon_plan_study_file_end_date: now_date_str,
    s_addon_attend: "1",
    s_addon_attend_start_date: now_date_str,
    s_addon_attend_end_date: now_date_str,
    // s_addon_month_prescribe: "1",
    // s_addon_month_prescribe_start_date: now_date_str,
    // s_addon_month_prescribe_end_date: now_date_str,
    // s_addon_month_prescribe_writer_seq: "",
    // s_addon_month_prescribe_comment: "1",
    // s_addon_month_prescribe_counsel_todo: "1",
  });
  const [tab_id, set_tab_id] = useState("lecture");
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [check_row_val_arr, set_check_row_val_arr] = useState([]);
  const [is_show_etc_group, set_is_show_etc_group] = useState(
    strFunc.get_storage("daily_report_is_show_etc_group", "") == "1" ? true : false
  );
  const [show_group_time,set_group_time]=useState({
    sort:strFunc.get_storage("change_group_time_sort",""),//"":전체,now::현재~,select:선택
    select_time_arr:[],//[13:00,14:00,..]
  });
  const able_time_arr_ref=useRef<any>([]);//[13:00,14:00,..]
  
  const [is_show_checkbox, set_is_show_checkbox] = useState(true);
  const [refresh, set_refresh] = useState(false);
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장 되었습니다.",
  });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",//add_attend,modify_attend,view_todo,view_stu
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    attend_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    userInfoArr: [],
    stu_info: {},

    isUpdate: false,
    rowData: {},
    todo_sort: "",
    select_date: "",
    comp_stu_info: {},

    row_num: "",
    key_str: "",
    par_info: null,

    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
    select_idx: 0,
    stu_info_arr: [],
  });

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let tmp_listOpt: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(tmp_listOpt);
    let tmp_list_form_json = { ...tmp_listOpt };
    let tmp_select_date = tmp_listOpt.s_addon_daily_report_start_date;
    tmp_list_form_json["s_addon_todo_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_todo_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_home_work_file_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_home_work_file_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_plan_study_file_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_plan_study_file_end_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_attend_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_attend_end_date"] = tmp_select_date;

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_form_json["api_key"] = comp_api_key;
    tmp_list_form_json["api_user_seq"] = iam_mcomp_user_seq;

    //하루전꺼부터 조회
    let tmp_start_date = tmp_list_form_json["s_addon_todo_start_date"];
    let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
    let pre_date_change_num = -1;
    if (tmp_start_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', pre_date_change_num), "Y-m-d");
    tmp_list_form_json["s_addon_todo_start_date"] = pre_date_str;
    tmp_list_form_json["s_addon_home_work_file_start_date"] = pre_date_str;
    //일일보고서 일주일 조회
    let week_date_change_num = -tmp_start_date_json.day;
    let week_date_change_end_num = 6 - tmp_start_date_json.day;
    let week_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', week_date_change_num), "Y-m-d");
    let week_date_end_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', week_date_change_end_num), "Y-m-d");
    tmp_list_form_json["s_addon_month_prescribe_start_date"] = week_date_str;
    tmp_list_form_json["s_addon_month_prescribe_end_date"] = week_date_end_str;
    tmp_list_form_json["s_addon_daily_report_start_date"] = tmp_select_date;
    tmp_list_form_json["s_addon_daily_report_end_date"] = tmp_select_date;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"] = TodoInfoArrFunc.get_info_arr_by_filter({
            info_arr: response.data["data"]["info_arr"],
            list_opt: tmp_list_form_json,
            base_date_str: tmp_select_date,
            pre_date_str: pre_date_str,
          });
          response.data["data"]["info_arr"] = DailyDataFunc.get_stu_list_of_setting_default_daily_info_arr({
            info_arr: response.data["data"]["info_arr"],
            select_date: tmp_start_date,
            tab_id: tab_id,
          });

          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const on_check_all = (e: any) => {
    if (e.target.checked) {
      let tmp_pri_val_arr: any = [];
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        let row_pri_val = info["a_seq"];
        tmp_pri_val_arr.push(row_pri_val);
      }
      set_check_row_val_arr(tmp_pri_val_arr);
    } else {
      set_check_row_val_arr([]);
    }
  };

  const get_select_daily_report_arr = (inData: any) => {
    let opt_obj = {
      is_except_new: false,
      select_date_str: listOpt.s_addon_daily_report_start_date,
      ...inData
    };
    let select_info_arr: any = [];

    for (let i = 0; i < infoArr.length; i++) {
      let info: any = infoArr[i];
      let row_pri_val = info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, check_row_val_arr) != -1) {
        if (info.daily_report_arr) {
          for (let j = 0; j < info.daily_report_arr.length; j++) {
            let row_daily_info = info.daily_report_arr[j];
            if (row_daily_info["a_date"] == opt_obj["select_date_str"]
              &&!strFunc.is_empty(row_daily_info["a_lecture_seq"])) {
              if (opt_obj.is_except_new) {
                if (!strFunc.is_empty(row_daily_info["a_seq"])) {
                  select_info_arr.push(row_daily_info);
                }
              } else {
                select_info_arr.push(row_daily_info);
              }
            }
          }
        }
      }
    }

    return select_info_arr;
  };

  const save_daily_report_by_stu_arr = (inData: any) => {
    let opt_obj = {
      report_data_arr: get_select_daily_report_arr({ is_except_new: false }),
      is_refresh_daily_study_title_arr:false,
      ...inData
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    //보고서 모으기
    let report_data_arr: any = opt_obj.report_data_arr;
    if (report_data_arr.length == 0) {
      alert("저장 할 데이터가 없습니다.");
      return false;
    }

    let w_report_form_json: any = {
      "data_arr": report_data_arr,
    };
    w_report_form_json["api_key"] = comp_api_key;
    w_report_form_json["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/write', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 600);
          let tmp_change_info_arr=DailyDataFunc.get_stu_info_arr_of_update_daily_info({
            info_arr: infoArr,
            update_daily_report_arr: response.data["data"],
          });
          setInfoArr(tmp_change_info_arr);
          if(opt_obj.is_refresh_daily_study_title_arr){
            refresh_daily_study_title_arr_by_ajax({
              info_arr:tmp_change_info_arr,
              report_data_arr:report_data_arr,
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const refresh_daily_study_title_arr_by_ajax=(inData:any)=>{
    let opt_obj={
      info_arr:infoArr,
      report_data_arr:[],
      ...inData,
    };
    let report_data_arr=opt_obj.report_data_arr;
    if(report_data_arr.length==0){
      return false;
    }
    let s_study_title_seq_arr=[];
    for(let i=0;i<report_data_arr.length;i++){
      if(!strFunc.is_empty(report_data_arr[i]["a_textbook_seq"])){
        s_study_title_seq_arr.push(report_data_arr[i]["a_textbook_seq"]);
      }
      if(!strFunc.is_empty(report_data_arr[i]["a_subtextbook_seq"])){
        s_study_title_seq_arr.push(report_data_arr[i]["a_subtextbook_seq"]);
      }
    }
    if(s_study_title_seq_arr.length==0){
      return false;
    }
    
    let tmp_form_json:any={
      "s_pri_arr":s_study_title_seq_arr,
      "s_addon_section":"1",
      "s_addon_study":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    tmp_form_json["api_key"] = comp_api_key;
    tmp_form_json["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', tmp_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let tmp_change_info_arr=DailyDataFunc.get_stu_info_arr_of_update_study_title_arr({
            info_arr: opt_obj["info_arr"],
            study_title_arr: response.data["data"]["info_arr"],
          });
          setInfoArr(tmp_change_info_arr);
        } else {
          //alert(response.data["msg"]);
        }
      });
  };

  const delete_daily_report_by_stu_arr = () => {
    //보고서 모으기
    let report_data_arr: any = get_select_daily_report_arr({ is_except_new: true });
    if (report_data_arr.length == 0) {
      alert("삭제 할 데이터가 없습니다.");
      return false;
    }

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let w_report_form_json: any = {
      "data_arr": report_data_arr,
    };
    w_report_form_json["api_key"] = comp_api_key;
    w_report_form_json["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/delete', w_report_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_loading_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_loading_data({
              ...loading_data,
              is_display: false,
            });
          }, 1200);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const deleteAttend = (dataArr: any, confirm_msg: string) => {
    if (!confirm(confirm_msg)) {
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let del_form_data: any = {
      "data_arr": dataArr
    };
    del_form_data["api_key"] = comp_api_key;
    del_form_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const get_select_info_arr = () => {
    let selected_row: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      if (strFunc.str_in_array(info["a_seq"], check_row_val_arr) != -1) {
        selected_row.push(info);
      }
    }
    return selected_row;
  };

  const openAttendPopup = (selected_row: [], attend_sort: string, attend_sort_detail: string) => {
    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    let select_sort_detail = [
      { "value": "attend", "text": "등원" },
      { "value": "late", "text": "등원(지각)" },
      { "value": "absent", "text": "결석" },
      { "value": "out", "text": "하원" },
    ];
    for (let i = 0; i < select_sort_detail.length; i++) {
      let item = select_sort_detail[i];
      if (item.value == attend_sort_detail) {
        pop_title = item.text;
      }
    }

    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date = listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "add_attend",
      "title": pop_title,
      attend_sort: attend_sort,
      attend_sort_detail: attend_sort_detail,
      select_date: attend_date,
      user_info_arr: selected_row,
      has_header: true,
    });
  };
  const openAttendBySelect=(inData:any)=>{
    let opt_obj={
      in_out:"in",
      sort_detail:"attend",
      ...inData,
    };
    let selected_row=get_select_info_arr();
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    openAttendPopup(selected_row,opt_obj.in_out,opt_obj.sort_detail);
  };

  const openViewTodoStuPopup = (inData:any) => {
    let opt_obj={
      stu_info:null,
      select_date:listOpt.s_addon_todo_start_date,
      ...inData
    };
    let stu_info=opt_obj["stu_info"];
    if (strFunc.is_empty(stu_info)) {
      alert("선택이 없습니다.");
      return false;
    }
    let pop_title = "맞춤플랜 보기";
    let select_idx = 0;
    for (let i = 0; i < infoArr.length; i++) {
      if (infoArr[i]["a_seq"] == stu_info["a_seq"]) {
        select_idx = i;
      }
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "view_todo",
      has_header: true,
      height: "100%",
      "title": pop_title,
      stu_info_arr: infoArr,
      select_idx: select_idx,
      select_date:opt_obj["select_date"],
    });
  };

  const go_write_plan_page = (inData: any) => {
    let opt_obj = {
      "is_update": false,
      "rowData": null,
      "stu_info_arr": get_select_info_arr(),
      "todo_sort": "homework",
      "select_date": listOpt.s_addon_attend_start_date,
      ...inData
    };
    let stu_info_arr: any = opt_obj["stu_info_arr"];
    if (opt_obj["is_update"]) {
      if (strFunc.is_empty(opt_obj["rowData"])) {
        alert("수정할 데이터 없음.");
        return false;
      }
    } else {
      if (stu_info_arr.length == 0) {
        alert("학생 선택이 필요합니다.");
        return false;
      }
      if (stu_info_arr.length != 1) {
        alert("한명만 선택해 주세요.");
        return false;
      }
    }
    
    let comp_stu_info = stu_info_arr[0];
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "write_todo",
      isUpdate: opt_obj["is_update"],
      rowData: opt_obj["rowData"],
      todo_sort: opt_obj["todo_sort"],
      select_date: opt_obj["select_date"],
      comp_stu_info: comp_stu_info,
    });
  };

  const open_find_study_title_popup = (inData: any) => {
    let opt_obj = {
      row_num: "",
      key_str: "",
      par_info: null,//daily_info
      ...inData
    };
    setPopupData({
      ...popupData,
      isOpen: true,
      sort: "find_study_title",
      title: "학습내용 찾기",
      row_num: opt_obj.row_num,
      key_str: opt_obj.key_str,
      par_info: opt_obj.par_info,
      has_header: true,
      width: "500px",
      height: "100%",
      ...opt_obj
    });
  };

  const callback_study_title = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: "",
      key_str: "",
      par_info: null,
      ...inData
    };
    if (opt_obj["info_arr"].length == 0) {
      return false;
    }
    let study_title_info=opt_obj["info_arr"][0];
    
    if (opt_obj.key_str == "a_textbook") {
      opt_obj["par_info"]["a_textbook"] = study_title_info["a_title"];
      opt_obj["par_info"]["a_textbook_seq"] = study_title_info["a_seq"];
    } else if (opt_obj.key_str == "a_subtextbook") {
      opt_obj["par_info"]["a_subtextbook"] = study_title_info["a_title"];
      opt_obj["par_info"]["a_subtextbook_seq"] = study_title_info["a_seq"];
    }

    save_daily_report_by_stu_arr({
      report_data_arr: [opt_obj["par_info"]],
      is_refresh_daily_study_title_arr:true,
    });
  };

  const callback_go_remove_select_title=(inData:any)=>{
    let opt_obj = {
      info_arr: [],
      row_num: "",
      key_str: "",
      par_info: null,
      ...inData
    };

    if(!confirm("교재선택을 해제하시겠습니까?")){
      return false;
    }
    
    if (opt_obj.key_str == "a_textbook") {
      opt_obj["par_info"]["a_textbook"] = "";
      opt_obj["par_info"]["a_textbook_seq"] = "";
    } else if (opt_obj.key_str == "a_subtextbook") {
      opt_obj["par_info"]["a_subtextbook"] = "";
      opt_obj["par_info"]["a_subtextbook_seq"] = "";
    }

    save_daily_report_by_stu_arr({
      report_data_arr: [opt_obj["par_info"]],
      is_refresh_daily_study_title_arr:true,
    });
  };

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
        is_show_etc_group={is_show_etc_group}
        set_is_show_etc_group={set_is_show_etc_group}
        show_group_time={show_group_time}
        set_group_time={set_group_time}
        able_time_arr_ref={able_time_arr_ref}
        infoArr={infoArr}
        save_daily_report_by_stu_arr={save_daily_report_by_stu_arr}
        delete_daily_report_by_stu_arr={delete_daily_report_by_stu_arr}
        openAttendBySelect={openAttendBySelect}
      ></SearchArea>
      <DailyListArea
        tab_id={tab_id}
        info_arr={infoArr}
        set_info_arr={(inData: any) => {
          setInfoArr(inData);
          set_refresh(!refresh);
        }}
        select_date={listOpt.s_addon_daily_report_start_date}
        list={list}
        check_row_val_arr={check_row_val_arr}
        set_check_row_val_arr={(inData: any) => {
          set_check_row_val_arr(inData);
          set_refresh(!refresh);
        }}
        is_show_checkbox={is_show_checkbox}
        is_show_etc_group={is_show_etc_group}
        show_group_time={show_group_time}
        set_group_time={set_group_time}
        able_time_arr_ref={able_time_arr_ref}
        save_daily_report_by_stu_arr={save_daily_report_by_stu_arr}
        deleteAttend={deleteAttend}
        openAttendPopup={openAttendPopup}
        open_write_td_data_popup={(inData: any) => { }}
        openViewTodoStuPopup={openViewTodoStuPopup}
        open_stu_layer_popup={(inData: any) => { }}
        open_find_study_title_popup={open_find_study_title_popup}
      ></DailyListArea>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        is_view_num_per_page={true}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      <LoadingComponent is_display={loading_data.is_display} text={loading_data.text}></LoadingComponent>
      <TopMoveButton></TopMoveButton>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={popupData.y} has_header={popupData.has_header}  >
          {popupData.sort === "add_attend" &&
            <AddAttendPopup attend_sort={popupData.attend_sort}
              attend_sort_detail={popupData.attend_sort_detail}
              attend_date={popupData.select_date}
              userInfoArr={popupData.user_info_arr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></AddAttendPopup>
          }
          {popupData.sort === "view_todo" &&
            <ViewTodoStuPopup
              select_idx={popupData.select_idx}
              info_arr={popupData.stu_info_arr}
              select_date={popupData.select_date}
              go_write_page={go_write_plan_page}
              callback={() => { list({ "now_page": "1" }); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewTodoStuPopup>
          }
          {popupData.sort == "write_todo" &&
            <TodoWritePopup
              isUpdate={popupData.isUpdate}
              rowData={popupData.rowData}
              todo_sort={popupData.todo_sort}
              select_date={popupData.select_date}
              comp_stu_info={popupData.comp_stu_info}
              is_comp_todo={true}
              is_comp_stu={true}
              callback={() => { list({ "now_page": "1" }); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></TodoWritePopup>
          }
          {popupData.sort == "view_stu" &&
            <ViewStuInfoPopup
              select_idx={popupData.select_idx}
              info_arr={popupData.stu_info_arr}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuInfoPopup>
          }
          {popupData.sort == "find_study_title" &&
            <FindStudyTitlePopup
              row_num={popupData.row_num}
              key_str={popupData.key_str}
              par_info={popupData.par_info}
              is_remove_select_title_btn={true}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callback={(inData:any)=>{
                callback_study_title(inData);
              }}
              go_remove_select_title={(inData:any)=>{
                callback_go_remove_select_title(inData);
              }}
            ></FindStudyTitlePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DailyReportCardListArea;