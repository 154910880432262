function BottomArea(props: any) {
  let myProps = {
    Style: {},
    open_buy_popup: (inData: any) => { },
    add_water_for_char: (inData: any) => { },
    open_inven_popup: (inData: any) => { },
    pre_water_cnt: 0,
    ...props
  };
  let Style = myProps.Style;
  let buy_char_btn_style = {
    fontWeight: 500,
    fontSize: "16px",
    color: "#fff",
    background: "#b36109",
    lineHeight: "20px",
    padding: 5,
    borderRadius: "10px"
  };

  return (
    <div className={Style.bottom_wrap}>
      <div className={Style.bottom_con}>
        <span className={Style.bottom_btn_right_wrap}>
          <button className={Style.bottom_flower_btn} onClick={myProps.open_buy_popup}
            style={buy_char_btn_style}>
            상점
          </button>
          <p className="mt-2">
            <button className={Style.bottom_flower_btn} onClick={myProps.open_inven_popup}
              style={buy_char_btn_style}>
              인벤
            </button>
          </p>
          <p className="mt-2">
            <button className={Style.bottom_flower_btn} onClick={myProps.add_water_for_char}
              style={{ ...buy_char_btn_style, top: 40 }}>
              물주기
              {myProps.pre_water_cnt > 0 &&
                " (" + myProps.pre_water_cnt + ")"
              }
            </button>
          </p>
        </span>
      </div>
      <div className={Style.bottom_center_text}>
        매일 물을 주면 양분점수 20점을 드려요!
      </div>
    </div>
  );
}
export default BottomArea;