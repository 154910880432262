import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import MonthPrescribeWriteArea from "@/pages/mobile/manage/edu/month_prescribe/common/write";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from "@/pcomponents/common/content/loading";
import TStuTodoViewArea from "./tstu_todo";

function ParViewArea(props: any) {
  let myProps = {
    push_info: {},
    closePopup: () => { },
    ...props
  };
  const navigate = useNavigate();
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  useEffect(() => {
    if (myProps.push_info.a_seq) {
      get_push_par_info_by_ajax();
    }
  }, [myProps.push_info.a_seq]);

  const [par_info_data, set_par_info_data] = useState<any>({
    mcomp_name: "",
    par_id: "",
    par_seq: "",
    par_info: null,
    month_prescribe_info: null,
    last_daily_report: null,
    par_comp_api_key: "",
    par_comp_user_seq: "1",
  });
  const [is_save_delay, set_is_save_delay] = useState(false);
  const [loading_data, setLoadingData] = useState({
    is_display: false,
    text: "로딩중.."
  });

  const get_push_par_info_by_ajax = () => {
    let push_pri_val = myProps.push_info.a_ymd + "," + myProps.push_info.a_seq;
    let form_json_data = {
      push_seq: push_pri_val
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/push/get_par_info_one', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_par_info_data(response.data["data"]);
        } else {

        }
      });
  };

  const move_par_info_page = () => {
    if (par_info_data.month_prescribe_info) {
      if (strFunc.str_in_array(iam_mcomp_user_grade, ["master", "teacher", "staff"]) != -1) {
        if (iam_mcomp_seq == myProps.push_info.a_par_mcomp_seq) {
          let send_data = {
            's_start_date': par_info_data.month_prescribe_info["a_reserve_date"],
            's_end_date': par_info_data.month_prescribe_info["a_reserve_date"],
          };
          navigate("/mobile/manage/month_prescribe/card_list", { state: send_data });
        } else {
          alert("선택된 학원이 다릅니다. " + par_info_data["mcomp_name"] + "을 선택해주세요.");
        }
      } else {
        alert("권한이 없습니다.");
      }
    }
  };

  const go_write_month_prescribe = (inData: any) => {
    let opt_obj = {
      row_data: {},
      is_show_loading: false,
      ...inData
    };
    if (is_save_delay) {
      return false;
    }
    if (strFunc.is_empty(opt_obj["row_data"]["a_seq"])) {
      return false;
    }
    let write_form_json_data: any = {
      data_arr: [opt_obj["row_data"]],
      is_update: "1",
    };

    if (myProps.push_info.a_par_mcomp_seq == iam_mcomp_seq) {
      write_form_json_data["api_key"] = comp_api_key;
      write_form_json_data["api_user_seq"] = iam_mcomp_user_seq;
    } else {
      for (let i = 0; i < user.comp_link_arr.length; i++) {
        let tmp_comp_link = user.comp_link_arr[i];
        if (tmp_comp_link["a_mcomp_seq"] == myProps.push_info.a_par_mcomp_seq) {
          write_form_json_data["api_key"] = tmp_comp_link["comp_api_key"];
          write_form_json_data["api_user_seq"] = tmp_comp_link["a_mcomp_user_seq"];
        }
      }
      if (strFunc.is_empty(write_form_json_data["api_key"])){
        write_form_json_data["api_key"]=par_info_data["par_comp_api_key"];
        write_form_json_data["api_user_seq"]=par_info_data["par_comp_user_seq"];
      }
    }
    if (strFunc.is_empty(write_form_json_data["api_key"])
      || strFunc.is_empty(write_form_json_data["api_user_seq"])) {
      alert("학원키가 없습니다.");
      return false;
    }
    set_is_save_delay(true);
    setTimeout(() => {
      set_is_save_delay(false);
    }, 1000);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/write', write_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (opt_obj.is_show_loading) {
            setLoadingData({
              is_display: true,
              text: "저장되었습니다."
            });
            setTimeout(() => {
              setLoadingData({
                is_display: false,
                text: ""
              });
              myProps.closePopup();
            }, 500);
          }
        } else {

        }
      });
  };
  
  return (
    <div>
      {!strFunc.is_empty(par_info_data.par_info) &&
        <div>
          {par_info_data.month_prescribe_info &&
            <div>
              <p className="text-center my-1" >
                월간학습처방 ({par_info_data["mcomp_name"]})
                <button className="btn btn-dark ml-1"
                  onClick={move_par_info_page}>상세보기</button>
              </p>
              <MonthPrescribeWriteArea
                select_mcomp_seq={myProps.push_info.a_par_mcomp_seq}
                select_mcomp_api_key={par_info_data.par_comp_api_key}
                select_mcomp_user_seq={par_info_data.par_comp_user_seq}
                rowData={par_info_data.month_prescribe_info}
                setRowData={(inData: any) => {
                  set_par_info_data({
                    ...par_info_data,
                    month_prescribe_info: inData
                  });
                  go_write_month_prescribe({ row_data: inData });
                }}
                list={get_push_par_info_by_ajax}
                isUpdate={true}
                is_view_mode={false}
                is_view_stu_info={true}
                last_daily_report={par_info_data.last_daily_report}
              ></MonthPrescribeWriteArea>
              <div className="mt-1 text-center">
                <button className="btn btn-dark" onClick={() => {
                  go_write_month_prescribe({
                    row_data: par_info_data.month_prescribe_info,
                    is_show_loading: true
                  });
                }}>저장</button>
              </div>
            </div>
          }
          {par_info_data.par_id=="tstu_todo" &&
            <TStuTodoViewArea
              row_data={par_info_data.par_info}
              refresh_data={get_push_par_info_by_ajax}
            ></TStuTodoViewArea>
          }
        </div>
      }
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}></LoadingComponent>
    </div>
  );
};
export default ParViewArea;