import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import StuTimeTableFunc from "@/pcomponents/common/content/edu/lecture/time_table/stu_time/func/stu_time_func";
import AttendBtnArea from "./attend_btns";

function AttendArea(props:any){
   let myProps={
    user:{},
    attend_arr:[],
    lecture_arr:[],
    desk_use_arr:[],
    open_add_attend_popup:(inData:any)=>{},
    ...props
  };
  let user=myProps.user;
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  //stu_union_time_arr
  let now_date_json=DateFunc.get_date_json(new Date());
  let stu_union_time_arr:any=[];
  for(let lec_i=0;lec_i<myProps.lecture_arr.length;lec_i++){
    let tmp_lecture=myProps.lecture_arr[lec_i];
    if(tmp_lecture.stu_union_time_arr&&tmp_lecture.stu_union_time_arr.length){
      for(let i=0;i<tmp_lecture.stu_union_time_arr.length;i++){
        let tmp_union_time=tmp_lecture.stu_union_time_arr[i];
        if(tmp_union_time["a_day_num"]==now_date_json.day){
          tmp_union_time["lecture_name"]=tmp_lecture["a_name"];
          tmp_union_time["room_name"]=tmp_lecture["a_room_name"];
          stu_union_time_arr.push(tmp_union_time);
        }
      }
    }
  }
  stu_union_time_arr=StuTimeTableFunc.get_ordered_stu_union_time_arr({
    stu_union_time_arr:stu_union_time_arr
  });

  const get_td_time_table_str=()=>{
    let td_time_table_str:any="";
    let time_table_str_arr:any=[];
    for(let i=0;i<stu_union_time_arr.length;i++){
      let tmp_stu_union_time=stu_union_time_arr[i];
        let start_time_str=strFunc.HourMinuteToTime(tmp_stu_union_time["a_start_h"],tmp_stu_union_time["a_start_m"]);
        let end_time_str=strFunc.HourMinuteToTime(tmp_stu_union_time["a_end_h"],tmp_stu_union_time["a_end_m"]);
        let time_table_str=tmp_stu_union_time["lecture_name"].substring(0,4)+" ("+start_time_str+"~"+end_time_str+")";
        time_table_str_arr.push(time_table_str);
    }

    let time_table_row_tags=time_table_str_arr.map((item:any,idx:number)=>{
      return(
        <div key={idx} style={{fontSize:"13px"}}>
          {item}
        </div>
      );
    });
    td_time_table_str=(
      <div>
        {time_table_row_tags}
      </div>
    );

    return td_time_table_str;
  };

  const get_desk_use_tags=()=>{
    let desk_use_tags=myProps.desk_use_arr.map((item:any,idx:number)=>{
      let start_hi=strFunc.HourMinuteToTime(item["a_start_h"],item["a_start_m"]);
      let end_hi=strFunc.HourMinuteToTime(item["a_end_h"],item["a_end_m"]);
      let desk_name="";
      if(item["a_name"]){
        desk_name=item["a_name"];
      }
      let room_name="";
      if(item["edu_room_info"]){
        room_name=item["edu_room_info"]["a_name"];
        room_name=room_name.substring(0,4);
      }
      let desk_use_str=room_name+" "+desk_name+" ("+start_hi+"~"+end_hi+")";
      return(
        <div key={idx} style={{fontSize:"13px",color:"green"}}>
          {desk_use_str}
        </div>
      );
    });

    return desk_use_tags;
  };
  
  return (
  <>
    {!strFunc.is_empty(iam_mcomp_seq)&&
      <div className="mt-4">
        <AttendBtnArea
          user={user}
          attend_arr={myProps.attend_arr}
          open_add_attend_popup={myProps.open_add_attend_popup}
        ></AttendBtnArea>
        <div className="mt-1">
          {get_td_time_table_str()}
        </div>
        {myProps.desk_use_arr.length>0&&
        <div className="mt-1">
          {get_desk_use_tags()}
        </div>
        }
      </div>
    }
  </>
  );
};
export default AttendArea;