import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    list_opt: {},
    list: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  const [subjectArr, setsubjectArr] = useState<any>([]);
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  useEffect(() => {
    list_corse_subject();
  }, []);

  const go_search = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if(e.target.type === "checkbox"){
      value = e.target.checked ? "1" : "";
    }
    myProps.list({
      "now_page": "1",
      [name]: value
    });
  };

  const list_corse_subject = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let subject_list_form_data: any = {
      "order_id": "a_order_num, a_title",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    subject_list_form_data["api_key"] = comp_api_key;
    subject_list_form_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setsubjectArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      <select className="search-input_select border" name="s_subject_sort" value={myProps.list_opt.s_subject_sort}
        onChange={go_search} style={{ width: 50 }} >
        <option value="">과목</option>
        {select_subject_option_arr.map((item: any, idx: number) => {
          return (
            <option value={item.value} key={idx}>{item.text}</option>
          );
        })}
      </select>
      <select className="search-input_select border ml-1" name="s_subject_seq" value={myProps.list_opt.s_subject_seq}
        onChange={go_search}
        style={{ width: 70 }}>
        <option value="">구분</option>
        {
          subjectArr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      <span className="ml-1">
        <label>
          <input type="checkbox" name="s_is_progress" value="1"
            checked={myProps.list_opt.s_is_progress == "1"}
            onChange={go_search} />
          <span className="ml-1">진도</span>
        </label>
      </span>
      <input type="text" className="search-input ml-1" value={myProps.list_opt.s_title_like} name="s_title_like"
        onChange={go_search}
        placeholder="제목"
        style={{ width: 60 }} />
      <button className="btn btn-dark ml-1" onClick={() => { myProps.list({}); }}>검색</button>
    </div>
  );
};
export default SearchArea;