import { useNavigate } from 'react-router-dom';
import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import strFunc from '@/lib/lyg/string';

function AttendBtnArea(props: any) {
  let myProps = {
    user: {},
    attend_arr: [],
    open_add_attend_popup: (inData: any) => { },
    ...props
  };
  const navigate = useNavigate();
  let user = myProps.user;
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  let in_out_info_data = AttendStateFunc.get_in_out_attend_info_data({
    attend_info_arr: myProps.attend_arr
  });
  let attend_data = AttendStateFunc.get_attend_data_by_in_out_info({
    in_info: in_out_info_data.in_info,
    out_info: in_out_info_data.out_info
  });
  let attend_time_str = "";
  if (in_out_info_data.in_info) {
    attend_time_str = in_out_info_data.in_info["a_time"];
    attend_time_str = attend_time_str.substring(0, 5);
  }

  let attend_btn_style: any = {
    display: "inline-block",
    marginLeft: "4px",
    lineHeight: "30px",
    padding: "0px 5px",
    borderRadius: "3px",
    color: "#fff",
    background: "gray",
    cursor: "pointer"
  };
  let attend_btn_style_json:any={
    "attned":{...attend_btn_style},
    "out":{...attend_btn_style},
    "absent":{...attend_btn_style},
  };
  if(in_out_info_data.in_info){
    if(in_out_info_data.in_info["a_sort_detail"]=="absent"){
      attend_btn_style_json.absent.background="orange";
    }else{
      attend_btn_style_json.attned.background="#53a500";
      if(in_out_info_data.in_info["a_sort_detail"]=="late"){
        attend_btn_style_json.attned.background="#eabf0b";
      }
    }
  }
  if(in_out_info_data.out_info){
    attend_btn_style_json.out.background="#7461ff";
  }

  const on_click_attend_btn = (inData: any) => {
    let opt_obj = {
      attend_sort: "in",
      attend_sort_detail: "attend",
      ...inData,
    };
    if(opt_obj.attend_sort=="out"){
      if(strFunc.is_empty(in_out_info_data.in_info)){
        alert("등원을 먼저 해주세요.");
        return false;
      }
      if(!strFunc.is_empty(in_out_info_data.in_info)){
        let tmp_in_info:any=in_out_info_data.in_info;
        if(tmp_in_info["a_sort_detail"]=="absent"){
          return false;
        }
      }
    }
    if(opt_obj.attend_sort=="in"){
      if(in_out_info_data.out_info){
        return false;
      }
    }
    
    myProps.open_add_attend_popup(opt_obj);
  };

  return (
    <div>
      <span style={{ cursor: "pointer" }} onClick={() => { navigate("/mobile/edu/attend_calendar"); }}>
        출석:
      </span>
      <span className="ml-2">
        <span style={attend_btn_style_json.attned}
          onClick={()=>{
            on_click_attend_btn({
              attend_sort: "in",
              attend_sort_detail: "attend",
            });
          }}>등원</span>
        <span style={attend_btn_style_json.out}
          onClick={()=>{
            on_click_attend_btn({
              attend_sort: "out",
              attend_sort_detail: "out",
            });
          }}>하원</span>
        <span style={attend_btn_style_json.absent}
          onClick={()=>{
            on_click_attend_btn({
              attend_sort: "in",
              attend_sort_detail: "absent",
            });
          }}>결석</span>
      </span>
    </div>
  );
};
export default AttendBtnArea;