import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';

const StudyTitleListArea = forwardRef((props: any, ref) => {
  const myProps = {
    onStudyTitleSelect: (inOptObj: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_title_like: "",
    s_subject_seq: "",
    s_subject_sort: "",
    s_addon_section: "1",//section_arr
    s_addon_study: "1",//study_arr
  });

  const [info_arr, set_info_arr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const setInitData = (inOptObj: any) => {
    if (inOptObj == undefined) {
      inOptObj = {};
    }
    let optObj = {
      "info_arr": [],
      "countInfo": { "tot": 0 },
      ...inOptObj
    };
    set_info_arr(optObj.info_arr);
    setCountInfo(optObj.countInfo);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData, get_select_info_arr
  }));

  useEffect(() => {

  }, []);

  const list = (inData: any) => {
    let form_json_data: any = {
      ...listOpt,
      ...inData
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    setListOpt(form_json_data);
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_info_arr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_select_info_arr = () => {
    return ListComponentRef.current.getSelectedRows();
  };

  const rowOnClick = (params: any) => {
  };

  const customCellRenderer = (params: any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
      }
      */
    let key=params.key;
    let row_val=params.value;
    if(key=="row_view_section"){
      let section_num=0;
      let study_num=0;
      if(params.rowData.section_arr){
        section_num=params.rowData.section_arr.length;
      }
      if(params.rowData.study_arr){
        study_num=params.rowData.study_arr.length;
      }
      row_val=section_num+"/"+study_num;
    }
    return row_val;
  };

  const onSelectChange = (select_info_arr: any) => {
    myProps.onStudyTitleSelect(select_info_arr);
  };

  return (
    <div className="text-center">
      <SearchArea
        list_opt={listOpt}
        list={list}
      ></SearchArea>
      <div className="mt-1">
        <ListComponent
          ref={ListComponentRef}
          infoArr={info_arr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_add_idx_num: true,
            is_add_checkbox: false,
            onGridReady: () => {
              list({});
            },
            rowOnClick: rowOnClick,
            customCellRenderer: customCellRenderer,
            onSelectChange: onSelectChange
          }}
        ></ListComponent>
      </div>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ num_per_page: num_per_page, now_page: 1 }); }}></Paging>
    </div>
  );
});

export default StudyTitleListArea;
