import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import UserGroupDataFunc from "@/pcomponents/common/content/list/tab1/func/group_data";

class DailyDataFunc {
  static get_stu_list_of_setting_default_daily_info_arr(inData: any) {
    let opt_obj = {
      info_arr: [],
      select_date: "",
      tab_id: "lecture",
      ...inData,
    };
    let info_arr = opt_obj.info_arr;
    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date: opt_obj.select_date,
      info_arr: info_arr,
      tab_id: opt_obj.tab_id,
    });

    let select_date_json = DateFunc.get_date_json(new Date(opt_obj["select_date"]));
    let select_day_str = DateFunc.get_day_str_by_day_num(select_date_json.day);
    let pre_date_change_num = -1;
    if (select_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(opt_obj["select_date"]), 'day', pre_date_change_num), "Y-m-d");

    let this_obj = this;
    //그룹 돌리면서 daily_info_arr 없는것 생성.
    for (let group_i = 0; group_i < group_data_arr.length; group_i++) {
      let group_info = group_data_arr[group_i];
      let lecture_info = group_info["lecture_info"];
      let stu_info_arr = group_info["info_arr"];
      for (let stu_i = 0; stu_i < stu_info_arr.length; stu_i++) {
        let info = stu_info_arr[stu_i];

        let last_daily_info = {
          a_stu_seq: "",
          a_writer_seq: "",
          a_lecture_seq: "",
          a_textbook: "",
          a_subtextbook: "",
          a_homework: "",
        };
        if (info.last_daily_report_arr && info.last_daily_report_arr.length > 0 && lecture_info != null) {
          for (let i = 0; i < info.last_daily_report_arr.length; i++) {
            let row_daily_info = info.last_daily_report_arr[i];
            if (row_daily_info["a_stu_seq"] == info["a_seq"]
              //&& row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
              && row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]) {
              last_daily_info = row_daily_info;
            }
          }
        }

        let daily_info = this_obj.get_daily_info_of_date({
          date_str: opt_obj.select_date,
          pre_date_str: pre_date_str,
          // is_now_date: true,
          last_daily_info: last_daily_info,
          lecture_time_str: group_info["time_str"],
          info: info,
          lecture_info: lecture_info,
        });

        if (strFunc.is_empty(daily_info["a_seq"])) {
          //daily_report_arr에 추가
          for (let i = 0; i < info_arr.length; i++) {
            if (info_arr[i]["a_seq"] == info["a_seq"]) {
              if (strFunc.is_empty(info_arr[i]["daily_report_arr"])) {
                info_arr[i]["daily_report_arr"] = [];
              }
              info_arr[i]["daily_report_arr"].push(daily_info);
            }
          }
        }
      }
    }

    return info_arr;
  };
  static get_daily_info_of_date(inData: any) {
    let opt_obj = {
      date_str: "",
      pre_date_str: "",
      is_now_date: false,
      last_daily_info: {},
      lecture_time_str: "",
      info: {},
      lecture_info: null,
      ...inData,
    };
    let lecture_info = opt_obj["lecture_info"];
    let info = opt_obj["info"];
    let daily_info: any = {
      a_textbook: "",
      a_textbook_seq: "",
      a_textbook_study: "",
      a_textbook_study_det: "",
      a_subtextbook: "",
      a_subtextbook_seq: "",
      a_subtextbook_study: "",
      a_subtextbook_study_det: "",
      a_memo: "",
      a_textbook_study_num: "0",
      a_textbook_study_det_num: "0",
      ...opt_obj.last_daily_info,
      a_ymd: strFunc.getNumber2(opt_obj["date_str"]),
      a_seq: "",
      a_date: opt_obj["date_str"],
      a_lecture_time: opt_obj.lecture_time_str,
      a_stu_seq: info["a_seq"],
      a_stu_name: info["a_user_name"],
      a_todo_sub_success_amount: "0",
      a_todo_sub_amount: "0",
      a_todo_success_amount:"0",
      a_todo_amount:"0",
    };

    let td_sort_data = {
      "sort": "study",
      "title": "학습",
      "percent": 0,
      "count": 0,
      "success_cnt": 0,
      "elapse_time_sec": 0,
      "per_arr": [],
      "todo_info_arr": [],
    };
    if (info.td_sort_data_json && info.td_sort_data_json["study"]) {
      td_sort_data = info.td_sort_data_json["study"];
    }

    //기존데이터 덮어쓰기
    if (info.daily_report_arr && info.daily_report_arr.length > 0 && lecture_info != null) {
      for (let i = 0; i < info.daily_report_arr.length; i++) {
        let row_daily_info = info.daily_report_arr[i];
        if (row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]
          && row_daily_info["a_stu_seq"] == info["a_seq"]
          //&& row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
          && row_daily_info["a_date"] == opt_obj["date_str"]) {
          daily_info = row_daily_info;
        }
      }
    }
    if (lecture_info != null) {
      daily_info["a_lecture_seq"] = lecture_info["a_seq"];
      daily_info["a_lecture_name"] = lecture_info["a_name"];
      daily_info["a_writer_seq"] = lecture_info["a_main_teacher_seq"];
      daily_info["a_writer"] = lecture_info["a_main_teacher"];
      daily_info["a_subject"] = lecture_info["a_subject"];
    }
    //오늘꺼 덮어쓰기
    if (opt_obj["is_now_date"]) {
      // daily_info["a_todo_success_amount"] = td_sort_data["count"];
      // daily_info["a_todo_amount"] = td_sort_data["success_cnt"];

      if (info.td_sort_data_json && info.td_sort_data_json["pre_homework"]) {
        daily_info["a_homework_per_num"] = info.td_sort_data_json["pre_homework"]["percent"];
      }

      let home_work_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
        info: info,
        date_sort: "pre",//pre,now
        select_date: opt_obj["date_str"],
        pre_date_str: opt_obj["pre_date_str"],
        file_sort: "homework"
      });
      daily_info["a_homework_file_cnt"] = home_work_file_cnt;
    }

    return daily_info;
  };
  static get_daily_percent(inData: any) {
    let opt_obj = {
      daily_info: {},
      is_sub: false,//sub
      ...inData
    };
    let daily_info = opt_obj["daily_info"];
    let daily_percent = 0;
    let a_todo_amount = parseInt(strFunc.uncomma(daily_info["a_todo_amount"]));
    let a_todo_success_amount = parseInt(strFunc.uncomma(daily_info["a_todo_success_amount"]));
    if (opt_obj.is_sub) {
      a_todo_amount = parseInt(strFunc.uncomma(daily_info["a_todo_sub_amount"]));
      a_todo_success_amount = parseInt(strFunc.uncomma(daily_info["a_todo_sub_success_amount"]));
    }

    if (a_todo_amount != 0 && a_todo_success_amount != 0) {
      daily_percent = Math.floor((a_todo_amount / a_todo_success_amount) * 100);
    }
    if (daily_percent > 100) { daily_percent = 100; }
    return daily_percent;
  }
  static get_stu_info_arr_of_update_daily_info(inData: any) {
    let opt_obj={
      info_arr:[],
      update_daily_report_arr:[],
      ...inData
    };
    let info_arr=opt_obj.info_arr;
    let update_daily_report_arr=opt_obj.update_daily_report_arr;

    for(let daily_i=0;daily_i<update_daily_report_arr.length;daily_i++){
      let daily_info=update_daily_report_arr[daily_i];
      for(let i=0;i<info_arr.length;i++){
        let info=info_arr[i];
        if(info["a_seq"]==daily_info["a_stu_seq"]){
          if(strFunc.is_empty(info["daily_report_arr"])){
            info["daily_report_arr"]=[];
          }
          let is_exist=false;
          for(let j=0;j<info["daily_report_arr"].length;j++){
            let pre_daily_info=info["daily_report_arr"][j];
            if(pre_daily_info["a_lecture_seq"]==daily_info["a_lecture_seq"]
              &&pre_daily_info["a_date"]==daily_info["a_date"]){
              info["daily_report_arr"][j]=daily_info;
              is_exist=true;
            }
          }
          if(!is_exist){
            info["daily_report_arr"].push(daily_info);
          }
        }
      }
    }

    return info_arr;
  };
  static get_stu_info_arr_of_update_study_title_arr(inData: any) {
    let opt_obj = {
      info_arr: [],
      study_title_arr: [],
      ...inData
    };
    let info_arr = opt_obj.info_arr;
    let study_title_arr = opt_obj.study_title_arr;
    let this_obj = this;

    for (let title_i = 0; title_i < study_title_arr.length; title_i++) {
      let title_info = study_title_arr[title_i];
      for (let i = 0; i < info_arr.length; i++) {
        let info = info_arr[i];
        if (info["daily_report_arr"]) {
          let is_exist_title = false;
          let tmp_daily_study_title_arr: any = [];
          for (let j = 0; j < info["daily_report_arr"].length; j++) {
            let daily_info = info["daily_report_arr"][j];
            if (daily_info["a_textbook_seq"] == title_info["a_seq"]) {
              tmp_daily_study_title_arr.push(title_info);
              is_exist_title = true;
            }
            if (daily_info["a_subtextbook_seq"] == title_info["a_seq"]) {
              tmp_daily_study_title_arr.push(title_info);
              is_exist_title = true;
            }
          }
          if (is_exist_title) {
            if (info["daily_study_title_arr"]) {
              info_arr[i]["daily_study_title_arr"]=this_obj.get_sum_two_study_title_arr({
                study_title_arr1: info["daily_study_title_arr"],
                study_title_arr2: tmp_daily_study_title_arr
              });
            }else{
              info_arr[i]["daily_study_title_arr"] = tmp_daily_study_title_arr;
            }
          } else {
            //info_arr[i]["daily_study_title_arr"]=[];
          }
        }
      }
    }

    return info_arr;
  };
  //두줄 합치기
  static get_sum_two_study_title_arr(inData: any) {
    let opt_obj = {
      study_title_arr1: [],
      study_title_arr2: [],
      ...inData
    };
    let study_title_arr = [...opt_obj.study_title_arr1];
    let study_title_arr2 = opt_obj.study_title_arr2;

    for (let i = 0; i < study_title_arr2.length; i++) {
      let title_info2 = study_title_arr2[i];
      let is_exist = false;
      for (let j = 0; j < study_title_arr.length; j++) {
        let title_info = study_title_arr[j];
        if (title_info["a_seq"] == title_info2["a_seq"]) {
          is_exist = true;
        }
      }
      if (is_exist == false) {
        study_title_arr.push(title_info2);
      }
    }

    return study_title_arr;
  };
}
export default DailyDataFunc;