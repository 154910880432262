import {  useEffect,useState } from 'react';

function Paging(props:any) {
  const [pageData, setPageData]= useState({
    now_page:1,
    block_size:5,
    total_rec:0,
    num_per_page:10,
    start_num:1,
    end_num:10,
    st_end_num_arr:[],
    max_end_num:0,
    st_limit:0,
    before_block:0,
    next_block:0,
    total_page:0,

    is_view_num_per_page:props.is_view_num_per_page,
    is_show_start_end:false,
    is_show_prev_next:true,
    is_show_total_rec:true,
  });
  useEffect(()=>{
    init();
  },[props.now_page,props.total_rec,props.num_per_page]);
  const init=()=>{
    let initPageData={...pageData};
    
    initPageData.now_page=props.now_page;
    initPageData.block_size=props.block_size;
    initPageData.total_rec=props.total_rec;
    initPageData.num_per_page=props.num_per_page;
    if(initPageData.now_page==undefined){initPageData.now_page=1;}
    if(initPageData.block_size==undefined){initPageData.block_size=5;}
    if(initPageData.total_rec==undefined){initPageData.total_rec=0;}
    if(initPageData.num_per_page==undefined){initPageData.num_per_page=20;}

    // 1 - 현재 페이지 설정
    if(initPageData.now_page==0||isNaN(initPageData.now_page)){initPageData.now_page=1;}
    // 3 - 각 블럭의 start 페이지 값을 설정한다
    if(initPageData.now_page % initPageData.block_size == 0){
        initPageData.start_num = initPageData.now_page - initPageData.block_size + 1;    // 현재 페이지가 블럭의 마지막 페이지 일 경우 해당 블럭의 시작 페이지 번호를 정한다
    }else{
        initPageData.start_num = Math.floor(initPageData.now_page/initPageData.block_size)*initPageData.block_size + 1; // 현재페이지가 블럭의 마지막 페이지가 아닐경우 시작 페이지를 지정한다
    }
    // 4 - 각 블럭의 end 페이지 값을 설정한다
    initPageData.end_num = initPageData.start_num + initPageData.block_size - 1;
    // 5 -- 총 개수

    // 7 - 불러오기 쿼리문에서 시작레코드 숫자 지정 ex(  limit($st_limit, $num_per_page)
    if(initPageData.now_page == 1){
        initPageData.st_limit = 0;
    }else{
        initPageData.st_limit = (initPageData.now_page * initPageData.num_per_page) - initPageData.num_per_page;
    }
    // 8 - 이전 블럭 설정
    initPageData.before_block=initPageData.start_num - 1;
    // 9 - 다음 블럭 설정
    initPageData.next_block=initPageData.start_num + initPageData.block_size;
    //전체 페이지 개수를 구한다.
    initPageData.total_page= Math.ceil( initPageData.total_rec/initPageData.num_per_page ) < 1 ? 1 : Math.ceil( initPageData.total_rec/initPageData.num_per_page );
    //페이징 배열세팅
    let st_end_num_arr:number[]=[];
    initPageData.max_end_num=Math.ceil(initPageData.total_rec/initPageData.num_per_page);
    for(let i=initPageData.start_num;i<=initPageData.end_num;i++){
      if(initPageData.max_end_num>=i){
        st_end_num_arr.push(i);
      }
    }
    initPageData.st_end_num_arr=st_end_num_arr as never[];

    setPageData({...initPageData});
  };
  const onChangePage=(now_page:number) => {
    props.onChangePage(now_page);
  };
  const onChangeNumPerPage=(num_per_page:number)=>{
    props.onChangeNumPerPage(num_per_page);
    init();
  };
  
  const pageNumBtnArr = pageData.st_end_num_arr.map((number)=>{
    let num_class_name="paging_num_btn";
    if(number==pageData.now_page){
      num_class_name="paging_num_btn active";
    }

    return (
      <li key={number}>
        <button className={num_class_name} onClick={()=>{onChangePage(number);}} >{number}</button>
      </li>
    );
  });
  return (
    <div className="paging_wrap">
      {pageData.total_rec!=0 &&
        <ul className="paging_ul">
          {pageData.is_show_start_end &&
          <li>
            <button className="paging_num_btn paging_left_btn" onClick={()=>{onChangePage(1);}}>처음</button>
          </li>
          }
          {pageData.is_show_prev_next &&
          <li>
            <button className="paging_num_btn paging_left_btn" onClick={()=>{onChangePage((pageData.now_page>1)?pageData.now_page-1:1);}}>이전</button>
          </li>
          }

          {pageNumBtnArr}

          {pageData.is_show_prev_next &&
          <li>
            <button className="paging_num_btn paging_right_btn" onClick={()=>{onChangePage(((pageData.now_page<pageData.total_page)?pageData.now_page+1:pageData.now_page));}}>다음</button>
          </li>
          }
          {pageData.is_show_start_end &&
          <li>
            <button className="paging_num_btn paging_right_btn" onClick={()=>{onChangePage(pageData.total_page);}}>끝</button>
          </li>
          }
          {pageData.is_view_num_per_page&&
          <li style={{lineHeight:"30px"}}>
            <select className="ml-1 border rounded" value={pageData.num_per_page} onChange={(e:any)=>{onChangeNumPerPage(e.target.value);}}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
            </select>
          </li>
          }
          {pageData.is_show_total_rec &&
          <li className="text-sm leading-8 con-p-gray pl-2">
            총 {pageData.total_rec} 건
          </li>
          }
        </ul>
      }
    </div>
  );
}

Paging.defaultProps = {
  now_page:1,
  block_size:5,
  total_rec:0,
  num_per_page:10,
  is_view_num_per_page:false,
  onChangePage:(now_page:number)=>{},
  onChangeNumPerPage:(num_per_page:number)=>{},
};

export default Paging;
